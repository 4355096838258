import React, { Component } from 'react';
import {
  IoLogoLinkedin,
  IoLogoTwitter,
  IoMdMail,
  IoLogoGithub,
} from 'react-icons/io';
import edDinnerPic from './assets/img/edatdinnerwithwine.jpg';
import edLunchPic from './assets/img/edatlunchwithsangria.jpg';
import edBreakfastPic from './assets/img/edatbreakfastwithcoffee.jpg';

import './App.css';

const meals = [
  { src: edBreakfastPic, alt: 'Pic of me eating breakfast.' },
  { src: edLunchPic, alt: 'Pic of me eating lunch.' },
  { src: edDinnerPic, alt: 'Pic of me eating dinner.' },
];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { meal: 0 }; // breakfast
  }

  render() {
    let { meal } = this.state;
    return (
      <div className="App">
        <div className="App-columns">
          <div className="App-pic-rows">
            <div onClick={() => this.setState({ meal: (meal + 1) % 3 })}>
              <img
                src={meals[meal].src}
                className="App-pic"
                alt={meals[meal].alt}
              />
            </div>
            <div className="App-columns">
              <svg width="60" height="10" style={{ marginTop: '-70px' }}>
                {meals.map((x, i) => (
                  <circle
                    cx={5 + 25 * i}
                    cy="5"
                    r="3"
                    fill={meal === i ? 'hotpink' : '#aaaaaaaa'}
                    stroke={meal === i ? 'hotpink' : '#ffffff88'}
                    stroke-width={meal === i ? '1' : '0'}
                  />
                ))}
              </svg>
            </div>
          </div>
          <div className="App-writeup">
            <p className="App-title">Hey!</p>
            <p>
              I'm Eduardo Villaseñor. I'm currently making{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://nombuds.com"
              >
                Nombuds
              </a>{' '}
              because I love food.
            </p>
            <p>
              Before that I made stuff to protect computers from “the bad guys”,
              and then stuff to make a bunch of advertising money. Both at{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://microsoft.com"
              >
                Microsoft
              </a>
              .
            </p>
            <p>
              Crypto{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://medium.com/s/story/why-decentralization-matters-5e3f79f7638e"
              >
                interests
              </a>{' '}
              me, increasingly so.
            </p>
            <p>
              Felt fortunate to join some talented friends to write and act in a
              way (WAY) off-Broadway{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://49thshelf.com/Books/C/Chile-Con-Carne-and-Other-Early-Works"
              >
                theatre production
              </a>
              . Used to give dance recitals to audiences in the tens to low
              thousands.
            </p>
            <p>
              Love adventuring in our 1987 VW camper. Faves include the{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/explore/tags/whitemountainscalifornia/"
              >
                White Mountains
              </a>{' '}
              and{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/explore/tags/bahiadelosangeles/"
              >
                Baja California
              </a>
              .
            </p>
            <p>
              I find this{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://youtu.be/MtlaxAhvEy8"
              >
                neat
              </a>
              .
            </p>
            <p>
              <a href="mailto:ed@nombuds.com">
                <IoMdMail />
              </a>{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://linkedin.com/in/eduardovillasenor"
              >
                <IoLogoLinkedin />
              </a>{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/ed_villasenor"
              >
                <IoLogoTwitter />
              </a>{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/eduardovillasenor"
              >
                <IoLogoGithub />
              </a>
            </p>
          </div>
        </div>
        <div className="App-copyright">Eduardo Villasenor © 2020</div>
      </div>
    );
  }
}

export default App;
